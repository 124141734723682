
.user-points-content {
    .search-content {
        padding-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .el-table-data {
        padding: 20px 0;
        height: calc(100% - 130px);
        .avatar-img {
            width: 80px;
            height: 80px;
            border-radius: 4px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
        }
    }
}

